import { useEffect } from "react";
import {
  Flex,
  Button,
  Heading,
  Image,
  VStack,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { Modal, TimerIcon } from "@components";
import { useSdkUtils, useProfile } from "@hooks";
import {
  generateSendToolUrl,
  formattedDate,
  daysRemaining,
  isTrialUser,
  isBasicUser,
} from "@utils/helpers";
import { getFromLocalStorage, saveToLocalStorage } from "@utils/localStorage";
import {
  TRIAL_DAYS,
  DAYS,
  PRO_TRIAL_LIST_ITEMS,
  TRIAL_MESSAGE,
  TRIAL_PERIOD_DAYS_TOKEN,
  TRIAL_MODAL_SEEN_TOKEN_NAME,
} from "@constants/proTrial";
import { PRICING_PAGE } from "@constants";

const ProTrialReminderModal = ({ handleBackToHome }) => {
  const { openUrl } = useSdkUtils();
  const {
    profile: { licenseType, trialPeriodStartDate },
  } = useProfile();
  const {
    isOpen: isTrialReminderModalOpen,
    onToggle: onToggleTrialReminderModal,
    onClose: onCloseTrialReminderModal,
  } = useDisclosure();

  const trialDate = formattedDate(trialPeriodStartDate, "YYYY-MM-DD");
  const now = formattedDate(new Date(), "YYYY-MM-DD");
  const trialRemainingDays = daysRemaining(TRIAL_DAYS, now, trialDate);
  const isShowModalDay = DAYS.includes(trialRemainingDays);
  const trialUser = isTrialUser(licenseType);
  const basicUser = isBasicUser(licenseType);
  const canShowModal =
    getFromLocalStorage(TRIAL_PERIOD_DAYS_TOKEN) !==
    trialRemainingDays.toString();
  const hasModalBeenShown =
    getFromLocalStorage(TRIAL_MODAL_SEEN_TOKEN_NAME) === now;

  const { messageText, textColor, backgroundColor, iconColor } =
    TRIAL_MESSAGE(trialRemainingDays);

  const handleClose = () => {
    onCloseTrialReminderModal();

    const today = formattedDate(new Date(), "YYYY-MM-DD");
    saveToLocalStorage(TRIAL_MODAL_SEEN_TOKEN_NAME, today);

    if (trialRemainingDays <= 0) {
      handleBackToHome();
    }
  };

  useEffect(() => {
    if ((!basicUser && !trialUser) || hasModalBeenShown) {
      return;
    }

    if (
      (trialUser && isShowModalDay && canShowModal) ||
      (basicUser && trialPeriodStartDate)
    ) {
      onToggleTrialReminderModal();
      saveToLocalStorage(TRIAL_PERIOD_DAYS_TOKEN, trialRemainingDays);
    }
  }, [
    licenseType,
    trialRemainingDays,
    trialUser,
    basicUser,
    isShowModalDay,
    canShowModal,
    trialPeriodStartDate,
    hasModalBeenShown,
  ]);

  return (
    <Modal
      size="xs"
      isOpen={isTrialReminderModalOpen}
      closeOnOverlayClick={false}
      onClose={handleClose}
      showCloseButton
    >
      <VStack spacing="3" mb="3">
        <Flex
          align="center"
          bg={backgroundColor}
          borderRadius="full"
          py="1"
          px="2"
        >
          <TimerIcon fill={iconColor} boxSize="3" />
          <Text color={textColor} fontSize="x-small">
            {messageText}
          </Text>
        </Flex>
        <Heading color="dark.600" fontWeight="700" fontSize="md">
          Keep Hosting Stress Low!
        </Heading>
        <Text fontSize="sm">
          You're trial has ended. Subscribe now to keep getting:
        </Text>
        <Box>
          {PRO_TRIAL_LIST_ITEMS.map((item, index) => (
            <Flex
              justify="flex-start"
              alignItems="center"
              key={`list-item-${index}`}
              my="2"
            >
              <Image src={item.icon} boxSize="5" mr="3" />
              <Flex flexDirection="column">
                <Text color="purple.800" fontSize="sm">
                  {item.text}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Button
          variant="primary"
          w="full"
          size="sm"
          onClick={() => openUrl(generateSendToolUrl(PRICING_PAGE))}
        >
          Subscribe Now
        </Button>
      </VStack>
    </Modal>
  );
};

export default ProTrialReminderModal;
